import * as React from "react"
import { SEO } from "../../components/seo"
import Layout from '../../components/vi/layout';
import ScrollAnimation from 'react-animate-on-scroll';
import { StaticImage } from "gatsby-plugin-image"

const Endorsements = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-72 bg-gradient-to-tl from-primary-dark to-primary-light relative after:content-[''] after:block after:bg-mask after:bg-['100%'] after:bg-bottom after:bg-no-repeat after:absolute after:inset-x-0 after:-bottom-1 after:w-full after:h-36 after:z-10">
        <div className="absolute w-full text-center inset-x-0 top-1/2 -translate-y-1/2 z-10 lg:p-20">
          <h1 className="animate__animated animate__fadeInDown animate__slower text-4xl lg:text-5xl text-white mb-4">Danh Sách Ủng Hộ</h1>
        </div>
      </section>
      <section className="my-14 text-gray-700 ">
        <div className="container mx-auto text-center">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">Lãnh Đạo Cấp Liên Bang</h2></ScrollAnimation>
              <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Dân Biểu Lou Correa</strong> (Dân Chủ-CA)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Dân Biểu Katie Porter</strong> (Dân Chủ-CA)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Dân Biểu Linda Sanchez</strong> (Dân Chủ-CA)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Dân Biểu Jill Tokuda</strong> (Dân Chủ-HI)<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Dân Biểu Sara Jacobs</strong> (Dân Chủ-CA)<br /></ScrollAnimation>
            </p>
        </div>
      </section>
      <section className="mx-auto py-20 px-4 text-gray-700 text-center bg-slate-100">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">Tổ Chức Đoàn Thể</h2></ScrollAnimation>

        <div className="container mx-auto text-center">
            <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><StaticImage src="../images/ca-dem-endorsement.png" alt="Đảng Dân Chủ California" placeholder="blurred" layout="constrained" className="mb-5" width={400} /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>California Democratic Party</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Los Angeles Times</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Asian Democrats of Los Angeles</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>ASPIRE PAC</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>BOLD PAC</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>California High School Democrats</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>California Young Democrats</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>CSUF College Democrats</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Equality California</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Hubert H. Humphrey Democratic Club</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Latinas Lead</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Latino Victory Fund</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Los Angeles County Democratic Party</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>National Women’s Political Caucus (NWPC)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Orange County Young Democrats (OCYD)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>PODER PAC</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Stonewall Democratic Club</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Women for American Values and Ethics (WAVE)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Yes We Can Democratic Club</strong><br /></ScrollAnimation>
            </p>
        </div>

      </section>
      <section className="mx-auto py-20 px-4 text-gray-700 text-center">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">Tổ chức lao động</h2></ScrollAnimation>

        <div className="container mx-auto text-center">
            <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>American Federation of Government Employees Local 1260</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>American Federation of Musicians Local 7</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Boilermakers Local 92</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>California Labor Federation</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>California Federation of Teachers</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>California Teachers Association</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Communications Workers of American, Local 9510</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>International Brotherhood of Teamsters Joint Council 42</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>International Brotherhood of Electrical Workers, Local 441</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>International Union of Longshoreman & Warehouse Workers, Southern California District Council</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>International Union of Painters & Allied Trades District Council 36</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>International Union of Operating Engineers (IUOE)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Ironworkers Local 433</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Ironworkers Local 416</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Laborers International Union of North America (LIUNA)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Los Angeles/Orange County Building & Construction Trades Council</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>National Union of Healthcare Workers (NUHW)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Office and Professional Employees International Union (OPEIA)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Orange County Employees Association (OCEA)</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Orange County Labor Federation</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Service Employees International Union (SEIU) California</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Sheet Metal, Air, Rail and Transportation Union</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>UNITE HERE Local 11</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>United Association of Refrigeration Fitters, Steamfitters, and Welders Local 250</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>United Food & Commercial Workers Local 324</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>United Steelworkers Local 675</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>United Union of Roofers, Waterproofers, & Allied Workers</strong><br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Western States Regional Council of Carpenters</strong><br /></ScrollAnimation>
            </p>
        </div>

      </section>
      <section className="text-gray-700 py-20 bg-slate-100">
        <div className="container mx-auto text-center">
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">Lãnh Đạo Cấp Tiểu Bang và Quận Hạt</h2></ScrollAnimation>
            <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>Thượng Nghị Sĩ <strong>Monique Limon</strong> Địa Hạt 19<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>Thượng Nghị Sĩ <strong>Tom Umberg</strong> Địa Hạt 34<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>Dân Biểu <strong>Sharon Quirk-Silva</strong> Địa Hạt 67<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>Dân Biểu <strong>Cottie Petrie-Norris</strong> Địa Hạt 73<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>Dân Biểu <strong>Josh Lowenthal</strong> Địa Hạt 69<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>Cựu Dân Biểu <strong>Tom Daly</strong> Địa Hạt 69<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>Giám Sát Viên Quận Cam <strong>Katrina Foley</strong> Địa Hạt 5<br /></ScrollAnimation>
            </p>
        </div>
      </section>
      <section className="my-8 px-10 lg:px-36 text-gray-700 text-center py-20">
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">Lãnh Đạo Cấp Thành Phố</h2></ScrollAnimation>
        <div className="container mt-10 mx-auto text-center">
            <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Natalie Rubalcava</strong>, Nghị Viên Anaheim<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Tiffany Ackley</strong>, Nghị Viên Aliso Viejo<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Richard Hurt</strong>, Nghị Viên Aliso Viejo<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Susan Sonne</strong>, Phó Thị Trưởng Buena Park<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Connor Traut</strong>, Nghị Viên Buena Park<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Jose Trinidad Castaneda</strong>, Nghị Viên Buena Park<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Frank Aurelio Yokoyama</strong>, Nghị Viên Cerritos<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Mark Pulido</strong>, Cựu Thị Trưởng Cerritos<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Gloria Pulido</strong>, Ủy Viên Quy Hoạch Cerritos<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Juan Garza</strong>, Ủy Viên Cơ Quan Thủy Cục Central Basin<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>John Stephens</strong>, Thị Trưởng Costa Mesa<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Arlis Reynolds</strong>, Nghị Viên Costa Mesa<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Andrea Marr</strong>, Nghị Viên Costa Mesa<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Manuel Chavez</strong>, Nghị Viên Costa Mesa<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Jocelyn Yow</strong>, Nghị Viên Eastvale<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Farrah Khan</strong>, Thị Trưởng Irvine<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Diedre Thu-Ha Nguyen</strong>, Cựu Nghị Viên Garden Grove<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>John O'Neill</strong>, Nghị Viên Garden Grove<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Luis Roa</strong>, Thị Trưởng Hawaiian Gardens<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Dan Kalmick</strong>, Nghị Viên Huntington Beach<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Glenn Grandis</strong>, Nghị Viên Fountain Valley<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Rhonda Bolton</strong>, Nghị Viên Huntington Beach<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Natalie Moser</strong>, Nghị Viên Huntington Beach<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Kim Carr</strong>, Cựu Nghị Viên Huntington Beach<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Alex Rounaghi</strong>, Nghị Viên Laguna Beach<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Janet Conklin</strong>, Nghị Viên La Palma<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Marshall Goodman</strong>, Nghị Viên La Palma<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Anthony Phan</strong>, Nghị Viên Milpitas<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Ellen Kamei</strong>, Nghị Viên Mountain View<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Rachel Hernandez</strong>, Nghị Viên Riverbank<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Anthony Rocha</strong>, Nghị Viên Salinas<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Joe Kalmick</strong>, Nghị Viên Seal Beach<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Valerie Amezcua</strong>, Thị Trưởng Santa Ana<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Phil Bacerra</strong>, Nghị Viên Santa Ana<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>David Penaloza</strong>, Nghị Viên Santa Ana<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Thai Viet Phan</strong>, Nghị Viên Santa Ana<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Hong Alyce Van</strong>, Nghị Viên Stanton<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Letitia Clark</strong>, Nghị Viên Tustin<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Beckie Gomez</strong>, Nghị Viên Tustin<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Jimmy Pham</strong>, Ủy Viên Giao Thông Westminster <br /></ScrollAnimation>
            </p>
        </div>
      </section>
      <section className="mx-auto my-8 py-20 px-4 lg:px-36 text-gray-700 text-center bg-slate-100">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><h2 className="font-bold text-xl mb-4 text-primary">Lãnh Đạo Học Khu</h2></ScrollAnimation>

        <div className="container mt-10 mx-auto text-center">
            <p className="text-base leading-loose">
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Ernie Nishii</strong>, Ủy Viên Giáo Dục Học Khu ABC Unified<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Mayra Garza</strong>, Chủ Tịch Hội Đồng Giáo Dục Học Khu Bellflower <br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Renita Armstrong</strong>, Ủy Viên Giáo Dục Học Khu Bellflower<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Jim Moreno</strong>, Ủy Viên Giáo Dục Học Khu Đại Học Cộng Đồng Coast<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Lorraine Prinsky</strong>, Ủy Viên Giáo Dục Học Khu Đại Học Cộng Đồng Coast<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Lamiya Hoque</strong>, Ủy Viên Giáo Dục Học Khu Centralia <br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Luis Flores</strong>, Ủy Viên Giáo Dục Học Khu Centralia <br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Phu Nguyen</strong>, Ủy Viên Giáo Dục Học Khu Fountain Valley <br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Ruthi Hanchett</strong>, Ủy Viên Giáo Dục Học Khu Fullerton <br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Aaruni Thakur</strong>, Ủy Viên Giáo Dục Học Khu Fullerton<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Walter Muneton</strong>, Garden Grove Unified School Board President<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Walter Muneton</strong>, Chủ Tịch Hội Đồng Giáo Dục Học Khu Garden Grove<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Barbara Dunsheath</strong>, Ủy Viên Giáo Dục Học Khu Đại Học Cộng Đồng North Orange County<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Tina Arias Miller</strong>, Ủy Viên Giáo Dục Học Khu Đại Học Cộng Đồng Rancho Santiago<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>Tina Gustin Gurney</strong>, Ủy Viên Giáo Dục Học Khu Westminster<br /></ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><strong>David Johnson</strong>, Ủy Viên Giáo Dục Học Khu Westminster<br /></ScrollAnimation>
            </p>
        </div>
      </section>
      <section className="mx-auto my-8 py-10 px-4 lg:px-36 text-gray-700 text-center">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <p className="text-sm text-center text-gray-600 mt-28 leading-loose">
          *Chức vụ chỉ dùng để nhận diện cá nhân.
          </p>
        </ScrollAnimation>
      </section>
    </Layout>
  )
}

export default Endorsements

export const Head = ({location}) => <SEO title="Danh Sách Ủng Hộ" pathname={location.pathname} />